@font-face {
  font-family: 'NeueMontreal-Regular';
  src: url(./assets/fonts/NeueMontreal/PPNeueMontreal-Regular.ttf) format('ttf');
}

@font-face {
  font-family: 'NeueMontreal-Medium';
  src: url(./assets/fonts/NeueMontreal/PPNeueMontreal-Medium.ttf) format('ttf');
}

$enable-negative-margins: true;

$success: #F096A5;
$primary: #F3AC7F;
$primaryLight: lighten($primary, 20%);
$secondary: #938E89;
$info: #B1A6EF;
$dark: #221F20;
$white: #FFFFFF;
$danger: #963143;

//Variables override
:root {
  // --bs-font-sans-serif: 'NeueMontreal-Regular', sans-serif !important;
  --primary-light: #{$primaryLight};
  --db-placeholder: #938E89;
  --db-blue: #5B6681;
  --db-purple: #7D6668;
  --db-green: #5C6442;
  --db-background: #EEECEA; // original - F6F5F4
  --db-brown: #8E5735;
  --db-gray: #EEECEA;
  --db-grayD: #F1F0EF;
  --db-peach: #F3AC7F;
  --db-black: #221F20;
  --db-white: #FFFFFF;
  --db-red: #963143;
  --db-overlay: #F5F09A;
  --db-highlights: #CCC6BA;
  --db-notifications: #F096A5;
  --db-max-width: 430px;
  --db-transition-padding: 'padding 400ms';
  --db-transition-all: 'all 200ms';
  --db-color-info: #B1A6EF;
  --db-txt-h1: 28px;
  --db-txt-h2: 24px;
  --db-txt-h3: 18px;
  --db-txt-h4: 16px;
  --db-txt-h5: 14px;
  --db-txt-h6: 12px;
  --db-fontsize-settings-link: 30px;
  --db-padding-container: 22px;
  --db-padding-container-sides: 13px;
  --db-padding-container-top: 70px;
  --db-padding-container-bottom: 80px; // this is a lot because of the floating menubar
  --db-padding-bottom: 70px;
  --db-border-radius: 25px;
  --db-padding-button: 10px 20px;
  --db-padding-button-small: 8px 16px;
  --db-container-gap: 20px;
  --db-border-radius-input: 10px;
  --background-color: var(--db-background);
  --spacing-tags: 5px;
}

// Override default variables before the import
// $body-color: #EEECEA;
$input-color: #8A8C8F;
$card-bg: #f0eae8;

// Import Bootstrap and its default variables
@import '~bootstrap/scss/bootstrap.scss';
@import '~bootstrap-icons/font/bootstrap-icons.scss';

html {
  overflow-y: scroll;
}

body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  background-color: var(--background-color);
}

// offcanvas stuff
.offcanvas {
  padding: 14px !important;
}

body,
nav {
  padding-right: 0 !important;
  padding-left: 0 !important;
  /* Ensure no padding is added to the left */
}

li.nav-item {
  padding: 12px 0px;
}

.btn-close {
  opacity: 1;
}

// form checks
.form-check-input.green-checkbox:checked {
  background-color: var(--db-green) !important;
  border-color: var(--db-green) !important;
}

// BG theme colors

.bg-blue {
  background-color: var(--db-blue) !important;
}

.bg-overlays {
  background-color: var(--db-overlay) !important;
}

.bg-highlights {
  background-color: var(--db-highlights) !important;
}

.bg-background {
  background-color: var(--db-background) !important;
}

.bg-green {
  background-color: var(--db-green) !important;
}

.bg-purple {
  background-color: var(--db-purple) !important;
}

.bg-notifications {
  background-color: var(--db-notifications) !important;
}

.bg-gray {
  background-color: var(--db-gray) !important;
}

.bg-brown {
  background-color: var(--db-brown) !important;
}

// TXT Theme colors

.text-blue {
  color: var(--db-blue) !important;
}

.text-dark,
.nav-link {
  color: var(--db-black) !important;
}

.text-green {
  color: var(--db-green) !important;
}

.text-purple {
  color: var(--db-purple) !important;
}

.text-brown {
  color: var(--db-brown) !important;
}

.text-background {
  color: var(--db-background) !important;
}

span.text-huge {
  font-size: 30px;
}

span.text-biggest {
  font-size: 20px;
}

.nav-link,
span.text-big {
  font-size: 18px;
}

span.text-medium {
  font-size: 18px;
}

span.text-small-medium {
  font-size: 16px;
}

p.text-small,
span.text-small {
  font-size: 14px;
}

span.text-smallest,
label.text-smallest {
  font-size: 12px;
}

.text-purple {
  color: var(--db-purple) !important;
}

.text-notifications {
  color: var(--db-notifications) !important;
}

// BORDER Theme colors

.border-purple {
  border-color: var(--db-purple) !important;
}

.border-blue {
  border-color: var(--db-blue) !important;
}

.border-green {
  border-color: var(--db-green) !important;
}

.border-brown {
  border-color: var(--db-brown) !important;
}

.border-notifications {
  border-color: var(--db-notifications) !important;
}

.border-settings {
  border-color: #c3c1bd !important;
}

// GENENRIC ELEMENTS

.nav-item {
  padding: 10px 0px;
}

h1,
h2 {
  color: #c58070;
}

a.btn-primary,
a.btn-primary:hover {
  color: white;
}

.text-size-date {
  font-size: 12px;
  padding: 4px 8px;
}

.filters-text {
  font-size: 0.8em;
}

a.pillbutton {
  text-decoration: none;
  color: var(--db-black);
}

.db-rounded {
  border-radius: 30px;
}

.tooltipImprovement {
  --bs-tooltip-bg: var(--db-color-info);
  --bs-tooltip-opacity: 1;
  --bs-tooltip-max-width: 250px;
}

.tooltipImprovement>.tooltip-inner {
  padding: 14px;
  color: var(--db-black);
  border-radius: 20px;
  text-align: left;
  font-size: 16px;
}

//ScrollBar
/* width */
::-webkit-scrollbar {
  width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: transparent;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: var(--db-gray);
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: var(--db-gray);
}

body,
* {
  font-family: 'NeueMontreal-Regular', sans-serif !important;
}

html {
  min-height: 100vh;
}

.location-selector__menu-list:after {
  content: "";
  padding: 1px 1px 1px 0;
  height: 18px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  text-align: right;
  display: block;
  background-image: url("https://maps.gstatic.com/mapfiles/api-3/images/powered-by-google-on-white3.png");
  background-position: right;
  background-repeat: no-repeat;
  -moz-background-size: 120px 14px;
  background-size: 120px 14px
}

.modal-header {
  border-bottom: 0px;
  padding: var(--db-padding-container);
}

.modal-body {
  padding: var(--db-padding-container);
  padding-top: 0px;
}

.modal {
  z-index: 3000;
}

.modal-content {
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.25);
}

.form-check-input:checked[type=checkbox] {
  background-color: var(--db-green) !important;
  border-color: var(--db-green) !important;
  --bs-form-check-bg-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'><path fill='none' stroke='white' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10l3 3l6-6'/></svg>");
}

.form-check-input:focus {
  border-color: var(--db-green) !important;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(173, 177, 160, 0.25);
}

.form-control:focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(204, 198, 186, 0.25);
}

.form-check-input {
  border: var(--bs-border-width) solid var(--db-highlights);
}

.form-check-input[type=checkbox] {
  border-radius: 0;
}

.tooltip.show {
  z-index: 3000;
}

.tooltip-inner {
  line-height: 1.4;
}

button {
  font-weight: normal;
}

.advanced-cropper-circle-stencil__preview {
  border: solid 2px rgba(0, 0, 0, 1) !important;
}